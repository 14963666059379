/* @define View */

@import "../base/global";
@import "../display";
@import "../text";

.View {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  color: var(--color);
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.View-container {
  padding-left: calc(var(--space) * 4);
  padding-right: calc(var(--space) * 4);
}

.View-container--fill {
  min-height: 100vh;
}

.View-container--nudge {
  padding-top: calc(var(--space) * 20);
}

.View-container--center {
  margin: auto;
}

.View-uncontain {
  margin-left: calc(var(--space) * -4);
  margin-right: calc(var(--space) * -4);
}

/**
 * 1. Fix the alignment with the nav and nav information.
 */

.View-header  {
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  padding: 0 calc(var(--space) * 4);
  padding-top: calc(var(--space) * 6); /* 1 */
  position: absolute;
  z-index: 2;
  user-select: none;
}

.View-header.is-fixed {
  position: fixed;
  opacity: 0;
  will-change: transform, opacity;
  transform: translateY(1rem);
  animation: View-header--fadeIn 350ms 200ms forwards var(--slide-in), View-header--slideIn 350ms 200ms forwards var(--slide-in);
}

@keyframes View-header--fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes View-header--slideIn {
  to {
    transform: translateY(0rem);
  }
}

.View-home {
  display: block;
  flex: 0 1 100%;
  margin-right: auto;
  width: 100%;
  max-width: 100px;
  position: relative;
  margin-top: calc((var(--space) * 2) * -1); /* 1 */
}

@media (min-width: 350px) {
  .View-home {
    max-width: 106px;
  }
}

@media (min-width: 600px) and (min-height: 400px) {
  .View-home {
    max-width: 120px;
  }
}

@media (min-width: 1000px) and (min-height: 600px) {
  .View-home {
    max-width: 130px;
    margin-top: -5px; /* 1 */
  }

  .View-header {
    padding-top: calc((var(--space) * 4) + 5px); /* 1 */
  }
}

@media (min-width: 1500px) and (min-height: 800px) {
  .View-home {
    max-width: 160px;
  }
}

@media (min-width: 2400px) and (min-height: 1000px) {
  .View-home {
    max-width: 200px;
  }
}

.View-logo {
  display: block;
  width: 100%;
  height: auto;
  color: var(--color);
}

.View-nav {
  list-style: none;
  display: flex;
  margin: 0.05em 0 0 0;
  padding: 0;
}

.View-nav > * {
  margin-left: calc(var(--space) * 4);
}

.View-link:hover {
  color: #fff;
  background: var(--color);
  padding: 0.08em 0.12em 0;
  margin: -0.08em -0.12em 0;
  border: 0;
}

/**
 * Footer
 */

.View-footer {
  display: flex;
  min-height: 90vh;
  padding-top: 3rem;
  padding-bottom: 10vh;
  flex-direction: column;
  justify-content: flex-end;
}

.View-contact {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20vh;
}

/**
 * Dividers
 */

.View-divider {
  margin: calc(var(--space) * 20) 0;
}


@media (max-width: 599px) {
  .View-divider--toMd {
    margin: calc(var(--space) * 20) 0;
  }
}

.View-divder--sm {
  margin: calc(var(--space) * 15) 0;
}

.View-divder--lg {
  margin: calc(var(--space) * 50) 0;
}

.View-headline {
  margin-bottom: calc((var(--space) * 2) * -1);
}

@media (min-width: 600px) and (min-height: 400px) {
  .View-headline {
    margin-bottom: calc((var(--space) * 6) * -1);
  }
}

/**
 * Grid
 */

.View-grid {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + (var(--space) * 4));
  margin: 0 calc((var(--space) * 2) * -1);
}

.View-grid--reverse {
  flex-wrap: wrap-reverse;
}

.View-cell {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  padding: 0 calc(var(--space) * 2);
}

.View-grid--tight {
  width: calc(100% + (var(--space) * 2));
  margin: 0 calc(var(--space) * -1);
}

.View-grid--tight .View-cell {
  padding: 0 var(--space);
}

.View-reverse {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
}

.View-reverse--sm,
.View-reverse--md,
.View-reverse--lg {
  flex-direction: column;
}

@media (min-width: 400px) {
  .View-reverse--sm {
    flex-direction: column-reverse;
  }
}

@media (min-width: 600px) {
  .View-reverse--md {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1000px) {
  .View-reverse--lg {
    flex-direction: column-reverse;
  }
}
