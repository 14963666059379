/* @define utilities */

.u-textInherit {
  font-size: inherit;
}

.u-textBold {
  font-weight: bold !important;
}

.u-textCenter {
  text-align: center !important;
}

.u-textNowrap {
  white-space: nowrap;
}