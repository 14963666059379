/* @define utilities */

.u-block {
  display: block !important;
}

.u-inlineBlock {
  display: inline-block !important;
}

.u-hidden {
  display: none !important;
}

/**
 * New block formatting context
 */

.u-nbfc {
  overflow: hidden !important;
}

.u-hiddenVisually {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

@media print {
  .u-printHidden {
    display: none !important;
  }
}
