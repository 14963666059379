/* @define utilities */

@import "./global";

/**
 * Top
 */

.u-spaceT0 {
  margin-top: 0 !important;
}

.u-spaceT1 {
  margin-top: var(--space) !important;
}

.u-spaceT2 {
  margin-top: calc(var(--space) * 2) !important;
}

.u-spaceT3 {
  margin-top: calc(var(--space) * 3) !important;
}

.u-spaceT4 {
  margin-top: calc(var(--space) * 4) !important;
}

.u-spaceT5 {
  margin-top: calc(var(--space) * 5) !important;
}

.u-spaceT6 {
  margin-top: calc(var(--space) * 6) !important;
}

.u-spaceT8 {
  margin-top: calc(var(--space) * 8) !important;
}

/**
 * Right
 */

.u-spaceR0 {
  margin-right: 0 !important;
}

.u-spaceR1 {
  margin-right: var(--space) !important;
}

.u-spaceR2 {
  margin-right: calc(var(--space) * 2) !important;
}

.u-spaceR3 {
  margin-right: calc(var(--space) * 3) !important;
}

.u-spaceR4 {
  margin-right: calc(var(--space) * 4) !important;
}

.u-spaceR5 {
  margin-right: calc(var(--space) * 5) !important;
}

.u-spaceR6 {
  margin-right: calc(var(--space) * 6) !important;
}

.u-spaceR8 {
  margin-right: calc(var(--space) * 8) !important;
}

/**
 * Bottom
 */

.u-spaceB0 {
  margin-bottom: 0 !important;
}

.u-spaceB1 {
  margin-bottom: var(--space) !important;
}

.u-spaceB2 {
  margin-bottom: calc(var(--space) * 2) !important;
}

.u-spaceB3 {
  margin-bottom: calc(var(--space) * 3) !important;
}

.u-spaceB4 {
  margin-bottom: calc(var(--space) * 4) !important;
}

.u-spaceB5 {
  margin-bottom: calc(var(--space) * 5) !important;
}

.u-spaceB6 {
  margin-bottom: calc(var(--space) * 6) !important;
}

.u-spaceB8 {
  margin-bottom: calc(var(--space) * 8) !important;
}

/**
 * Left
 */

.u-spaceL0 {
  margin-left: 0 !important;
}

.u-spaceL1 {
  margin-left: var(--space) !important;
}

.u-spaceL2 {
  margin-left: calc(var(--space) * 2) !important;
}

.u-spaceL3 {
  margin-left: calc(var(--space) * 3) !important;
}

.u-spaceL4 {
  margin-left: calc(var(--space) * 4) !important;
}

.u-spaceL5 {
  margin-left: calc(var(--space) * 5) !important;
}

.u-spaceL6 {
  margin-left: calc(var(--space) * 6) !important;
}

.u-spaceL8 {
  margin-left: calc(var(--space) * 8) !important;
}

/**
 * Intro
 */

.u-spaceIntro {
  margin: 12vh 0 2.5vh;
}

.u-spaceIntro--alt {
  margin: 15vh 0 calc(var(--space) * 10);
}

@media (min-width: 600px) {
  .u-spaceIntro {
    margin: 20vh 0 0;
  }

  .u-spaceIntro--alt {
    margin: 10vh 0 calc(var(--space) * 8);
  }
}