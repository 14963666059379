/* @define utilities */

.u-slideIn {
  opacity: 0;
  transform: translateY(150px);
  animation: slideIn 350ms 300ms forwards var(--slide-in), fadeIn 350ms 300ms forwards var(--slide-in);
  will-change: transform, opacity;
}

@keyframes slideIn {
  to {
    transform: translate(0px, 0px);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
